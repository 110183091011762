<template>
  <div>
    {{ action }}
  </div>
</template>

<script>
import pusher from '../PusherBus';

export default {
  name: 'PusherTesting',
  data() {
    return {
      action: true
    }
  },
  methods: {
    subscribe() {
      pusher.subscribe('my-channel');
      pusher.bind('my-event', () => {
        this.action = false;
      })
    }
  },
  created() {
    this.subscribe();
  }
}
</script>
